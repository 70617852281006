

import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectToken } from "../redux/auth/selectors";
import { io } from "socket.io-client";
import { url } from "components/other/terms";

export const useWebSocket = (onMessage, onError) => {
    const token = useSelector(selectToken); // Отримуємо токен із стану Redux
    const socketRef = useRef(null);
    // const baseUrl = "https://test-backend.stravaporuch.com.ua"; // Базовий URL без порту, якщо сервер працює на 443
    const baseUrl =url;
    useEffect(() => {
        if (!token) {
            console.warn("Токен не знайдено. WebSocket з'єднання не встановлено.");
            return;
        }

        console.log('Attempting to connect with token:', token);

        // Підключення через socket.io
        const socket = io.connect(baseUrl + ':443', {
            path: "/ws", // Вказуємо шлях до WebSocket
            query: { auth_token: `Bearer ${token}` }, // Передаємо токен через query
            secure: true, // Включаємо безпеку
        });

        socketRef.current = socket;

        // Обробка подій WebSocket
        socket.on("connect", () => {
            console.log('Socket connected');
        });

        socket.on("message", (data) => {
            console.log('Received message:', data);
            if (onMessage) {
                onMessage(data);
            }
        });

        socket.on("error", (err) => {
            console.error('Socket error:', err);
            if (onError) {
                onError(err);
            }
        });

        socket.on("disconnect", () => {
            console.log('WebSocket disconnected');
        });

        // Очищення при роз'єднанні
        return () => {
            if (socketRef.current) {
                console.log('Disconnecting WebSocket...');
                socketRef.current.disconnect();
            }
        };
    }, [token, onMessage, onError]);

    return socketRef.current;
};
