import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { url } from 'components/other/terms';
export const usersApi = createApi({
  reducerPath: 'users',
  baseQuery: fetchBaseQuery({
    baseUrl: url+'/users',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['Users'],

  endpoints: (builder) => ({
    getUsers: builder.query({
      query: ({ page = 1, role = '', id = '', email = '', name = '',  } = {}) => {
        const params = { page, role, id, email, name };
        return {
          url: ``,
          method: 'GET',
          params,
        };
      },
      transformResponse: (response, meta) => {
        const pagination = {
          currentPage: meta.response.headers.get('x-pagination-current-page'),
          pageCount: meta.response.headers.get('x-pagination-page-count'),
          perPage: meta.response.headers.get('x-pagination-per-page'),
          totalCount: meta.response.headers.get('x-pagination-total-count'),
        };
        return { data: response, pagination };
      },
      providesTags: ['Users'],
    }),
    blockUser: builder.mutation({
      query: (id) => ({
        url: `/block?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Users'],
    }),
    unblockUser: builder.mutation({
      query: (id) => ({
        url: `/unblock?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Users'],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/delete?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Users'],
    }),
    restoreUser: builder.mutation({
      query: (id) => ({
        url: `/restore?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Users'],
    }),
    confirmMerchant: builder.mutation({
      query: ({ id, date }) => ({
        url: `/confirm-merchant?id=${id}&date=${date}`,
        method: 'POST',
      }),
      invalidatesTags: ['Users'],
    }),
    createAdmin: builder.mutation({
      query: (data) => ({
        url: `/create-admin`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Users'],
    }),
    getMerchants: builder.query({
      query: () => ({
        url: `/merchants`,
        method: 'GET',
      }),

    }),
    getClients: builder.query({
      query: () => ({
        url: `/clients`,
        method: 'GET',
      }),

    }),
  }),
});

export const {
  useGetUsersQuery,
  useBlockUserMutation,
  useUnblockUserMutation,
  useDeleteUserMutation,
  useRestoreUserMutation,
  useConfirmMerchantMutation,
  useCreateAdminMutation,
  useGetMerchantsQuery,
  useGetClientsQuery
} = usersApi;
