import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { url } from 'components/other/terms';
export const statisticsApi = createApi({
  reducerPath: 'statistics',

  baseQuery: fetchBaseQuery({
    baseUrl: url+'/statistics',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),

  tagTypes: ['Statistics'],

  endpoints: builder => ({
    getGeneral: builder.query({
      query: (period) => ({
        url: `/general?period=${period}`,
        method: 'GET',
      }),

      providesTags: ['Statistics'],
    }),

    getRating: builder.query({
      query: ({ count, type }) => ({
        url: `/rating?count=${count}&type=${type}`,
        method: 'GET',
      }),

      providesTags: ['Statistics'],
    }),
    getCancelled: builder.query({
      query: ({ count, type }) => ({
        url: `/cancelled?count=${count}&type=${type}`,
        method: 'GET',
      }),

      providesTags: ['Statistics'],
    }),
    getReverted: builder.query({
      query: ({ count, type }) => ({
        url: `/reverted?count=${count}&type=${type}`,
        method: 'GET',
      }),

      providesTags: ['Statistics'],
    }),
    // getFeedbacks: builder.query({
    //   query: (period) => ({
    //     url: `/feedbacks?period=${period}`,
    //     method: 'GET',
    //   }),

    //   providesTags: ['Statistics'],
    // }),
    getFeedbacks: builder.query({
      query: (period) => ({
        url: period ? `/feedbacks?period=${period}` : '/feedbacks', // Додаємо параметр тільки якщо period присутній
        method: 'GET',
      }),

      providesTags: ['Statistics'],
    }),

    getComments: builder.query({
      query: ({ period, page = 1 } = {}) => {
        const params = {
          page, // додаємо параметр page до запиту
          period
        };
        return {
          url: period ? `/comments?period=${period}` : '/comments', // Додаємо параметр тільки якщо period присутній
          method: 'GET',
          params,
        };
      },
      transformResponse: (response, meta) => {
        const pagination = {
          currentPage: meta.response.headers.get('x-pagination-current-page'),
          pageCount: meta.response.headers.get('x-pagination-page-count'),
          perPage: meta.response.headers.get('x-pagination-per-page'),
          totalCount: meta.response.headers.get('x-pagination-total-count'),
        };
        return { data: response, pagination };
      },
      providesTags: ['Statistics'],
    }),

  }),
});

export const {
  useGetGeneralQuery,
  useGetRatingQuery,
  useGetCancelledQuery,
  useGetRevertedQuery,
  useGetFeedbacksQuery,
  useGetCommentsQuery
} = statisticsApi;
