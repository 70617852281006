import { Suspense, lazy, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
  selectIsFetchingCurrentUser,
  selectIsLoggedIn,
} from '../redux/auth/selectors';
import { getCurrentUser } from '../redux/auth/operations';
import MainLayout from './layout/Layout/Layout';
import ImageAnimation from './layout/loader/ImageAnimation';
import MyLottieAnimation from './layout/loader/MyLottieAnimation';
import DocsListPage from 'pages/docs/DocsListPage';
import DocsEditPage from 'pages/docs/DocsEditPage';
import ServicesTypePage from 'pages/services/ServicesTypePage';




const RegisterPage = lazy(() => import('../pages/registration/RegisterPage'));
const LoginPage = lazy(() => import('../pages/registration/LoginPage'));
const MainPage = lazy(() => import('../pages/main/MainPage'));
const DashBoardPage = lazy(() => import('../pages/main/DashBoardPage'));
const CalendarPage = lazy(() => import('../pages/main/CalendarPage'));
const AddBagPage = lazy(() => import('../pages/main/AddBagPage'));

const ServicesPage = lazy(() => import('../pages/services/ServicesPage'));
const SettingsPage = lazy(() => import('../pages/settings/SettingsPage'));
const NotFoundPage = lazy(() => import('../pages/registration/NotFoundPage'));
const VerifyEmail = lazy(() => import('../pages/registration/VerifyPage'));
const PasswordRecoveryPage = lazy(() => import('../pages/registration/PasswordRecoveryPage'));
const RenewPasswordPage = lazy(() => import('../pages/registration/RenewPasswordPage'));
const ViewBagPage = lazy(() => import('../pages/main/ViewBagPage'));
const ArchivePage = lazy(() => import('../pages/main/ArchivePage'));
const ShopSettingsPage = lazy(() => import('../pages/settings/ShopSettingsPage'));
const ShopEditSettingsPage = lazy(() => import('../pages/settings/ShopEditSettingsPage'));
const ShopAddSettingsPage = lazy(() => import('../pages/settings/ShopAddSettingsPage'));
const MessagePage = lazy(() => import('../pages/settings/MessagesPage'));
const ConfidentialityPage = lazy(() => import('../pages/settings/ConfidentialityPage'));
const CommentsPage = lazy(() => import('../pages/CommentsPage'));
const DocsPage = lazy(() => import('../pages/docs/DocsPage'));
const UserViewPage = lazy(() => import('../pages/main/UserViewPage'));
const SearchPage = lazy(() => import('../pages/search/SearchPage'));
const SearchBagPage = lazy(() => import('../pages/search/SearchBagPage'));
const SearchOrderPage = lazy(() => import('../pages/main/SearchOrderPage'));
const ViewOrderPage = lazy(() => import('../pages/main/ViewOrderPage'));
const ServicesQuestPage = lazy(() => import('../pages/services/ServicesQuestPage'));
const ServicesClientQuestPage = lazy(() => import('../pages/services/ServicesClientQuestPage'));
const ServicesClientReviewsPage = lazy(() => import('../pages/services/ServicesClientReviewsPage'));

export const App = () => {

  const dispatch = useDispatch();
  const isFetching = useSelector(selectIsFetchingCurrentUser);
  const isLogged = useSelector(selectIsLoggedIn);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);
  useEffect(() => {
    // Симулюємо завантаження анімації
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000); // 
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {isLoading ? (
        <MyLottieAnimation />
      ) : (
        <Suspense fallback={<ImageAnimation />}>
          <Routes>


            <Route
              path="/register"
              element={
                <RestrictedRoute
                  component={<RegisterPage />}
                  navigateTo="/main"
                />
              }
            />

            <Route
              path="/"
              element={
                <RestrictedRoute component={<LoginPage />} navigateTo="/main" />
              }
            />
            <Route
              path="/renew-password"
              element={
                <RestrictedRoute
                  component={<RenewPasswordPage />}
                  navigateTo="/main"
                />
              }
            />


            <Route
              path="/password_recovery"
              element={
                <RestrictedRoute
                  component={<PasswordRecoveryPage />}
                  navigateTo="/main"
                />
              }
            />

            <Route path="/" element={<MainLayout />}>


              <Route
                path="main/"
                element={
                  <PrivateRoute component={<MainPage />} navigateTo="/" />
                }
              >
                <Route
                  path=""
                  element={
                    <PrivateRoute component={<DashBoardPage />} navigateTo="/" />
                  }
                />
                <Route
                  path="calendar/:currentmonth"
                  element={
                    <PrivateRoute component={<CalendarPage />} navigateTo="/" />
                  }
                />

                <Route
                  path="archivebags"
                  element={
                    <PrivateRoute component={<ArchivePage />} navigateTo="/" />
                  }
                />
                  <Route
                    path="searching/"
                    element={
                      <PrivateRoute component={<SearchOrderPage />} navigateTo="/" />
                    }
                      />
                <Route
                  path="viewbag/:id"
                  element={
                    <PrivateRoute component={<ViewBagPage />} navigateTo="/" />
                  }
                />

                <Route
                  path="vieworder/:orderid"
                  element={
                    <PrivateRoute component={<ViewOrderPage />} navigateTo="/" />
                  }
                />
                <Route
                  path="viewuser/:id"
                  element={
                    <PrivateRoute component={<UserViewPage />} navigateTo="/" />
                  }
                />

              </Route>

              {/* <Route
      path="search/"
      element={
        <PrivateRoute component={<SearchPage />} navigateTo="/" />
      }
    >
      
      <Route
        path="bags"
        element={
          <PrivateRoute component={<SearchBagPage />} navigateTo="/" />
        }
      />
      <Route
        path="orders"
        element={
          <PrivateRoute component={<SearchOrderPage />} navigateTo="/" />
        }
      />
    </Route> */}

              <Route
                path="comments/"
                element={
                  <PrivateRoute component={<CommentsPage />} navigateTo="/" />
                }
              />
              <Route
                path="documents/"
                element={
                  <PrivateRoute component={<DocsPage />} navigateTo="/" />
                }
              >
                <Route
                  path=""
                  element={
                    <PrivateRoute component={<DocsListPage />} navigateTo="/" />
                  }
                />
                <Route
                  path="adddoc/:id"
                  element={
                    <PrivateRoute component={<DocsEditPage />} navigateTo="/" />
                  }
                />
              </Route>

              {/* <Route
                path="statistics/"
                element={
                  <PrivateRoute component={<StatisticsPage />} navigateTo="/" />
                }
              >
                <Route
                  path=""
                  element={
                    <PrivateRoute component={<GeneralStatisticsPage />} navigateTo="/" />
                  }
                />
                <Route
                  path="shop"
                  element={
                    <PrivateRoute component={<ShopStatisticsPage />} navigateTo="/" />
                  }
                />
                <Route
                  path="review"
                  element={
                    <PrivateRoute component={<ReviewStatisticsPage />} navigateTo="/" />
                  }
                />
              </Route> */}
              {/* <Route
                path="financial/"
                element={
                  <PrivateRoute component={<FinancialPage />} navigateTo="/" />
                }
              /> */}
              <Route
                path="services/"
                element={
                  <PrivateRoute component={<ServicesPage />} navigateTo="/" />
                }
              >
                <Route
                  path=""
                  element={
                    <PrivateRoute component={<ServicesTypePage />} navigateTo="/" />
                  }
                />
                <Route
                  path="question/"
                  element={
                    <PrivateRoute component={<ServicesQuestPage />} navigateTo="/" />
                  }
                  />
                  <Route
                    path="question_client/"
                    element={
                      <PrivateRoute component={<ServicesClientQuestPage/>} navigateTo="/" />
                    }
                  />
                  <Route
                    path="reviews_client/"
                    element={
                      <PrivateRoute component={<ServicesClientReviewsPage />} navigateTo="/" />
                    }
                  />
              </Route>
              <Route
                path="/settings"
                element={
                  <PrivateRoute component={<SettingsPage />} navigateTo="/" />
                }
              >
                <Route
                  path=""
                  element={
                    <PrivateRoute component={<ShopSettingsPage />} navigateTo="/" />
                  }
                />
                <Route
                  path="edit"
                  element={
                    <PrivateRoute component={<ShopEditSettingsPage />} navigateTo="/" />
                  }
                />
                <Route
                  path="addshop"
                  element={
                    <PrivateRoute component={<ShopAddSettingsPage />} navigateTo="/" />
                  }
                />
                <Route
                  path="message"
                  element={
                    <PrivateRoute component={<MessagePage />} navigateTo="/" />
                  }
                />
                <Route
                  path="confidentiality"
                  element={
                    <PrivateRoute component={<ConfidentialityPage />} navigateTo="/" />
                  }
                />

              </Route>
            </Route>

            <Route path="/verify/:verifyToken" element={<VerifyEmail />} />

            <Route
              path="*"
              element={
                <RestrictedRoute component={<NotFoundPage />} navigateTo="/" />
              }
            />
          </Routes>
          <ToastContainer />
        </Suspense>)}
    </>
  );
};

function RestrictedRoute({ component, navigateTo = '/' }) {
  const isLogged = useSelector(selectIsLoggedIn);

  return isLogged ? <Navigate to={navigateTo} /> : component;
}

function PrivateRoute({ component, navigateTo = '/' }) {
  const isLogged = useSelector(selectIsLoggedIn);
  const isFetching = useSelector(selectIsFetchingCurrentUser);

  return !isFetching && !isLogged ? <Navigate to={navigateTo} /> : component;
}

