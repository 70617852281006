import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { url } from 'components/other/terms';
export const bagsApi = createApi({
  reducerPath: 'bags',

  baseQuery: fetchBaseQuery({
    baseUrl: url+'/bags',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),

  tagTypes: ['Bags'],

  endpoints: builder => ({

    getBags: builder.query({
      query: ({ page = 1, merchant_id = '', keyword = '', latitude = '', longitude = '', categories = [], datetime_from = '', datetime_to = '', sort = '' } = {}) => {
        const params = new URLSearchParams({
          page,
          merchant_id,
          keyword,
          latitude,
          longitude,
          datetime_from,
          datetime_to,
          sort,
        });
        categories.forEach((category) => {
          params.append('categories[]', category);
        });
        return {
          url: `/all`,
          method: 'GET',
          params,
        };
      },
      transformResponse: (response, meta) => {
        const pagination = {
          currentPage: meta.response.headers.get('x-pagination-current-page'),
          pageCount: meta.response.headers.get('x-pagination-page-count'),
          perPage: meta.response.headers.get('x-pagination-per-page'),
          totalCount: meta.response.headers.get('x-pagination-total-count'),
        };
        return { data: response, pagination };
      },
      providesTags: ['Bags'],
    }),

    getBagDetails: builder.query({
      query: (id) => ({
        url: `/view`,
        method: 'GET',
        params: { id },
      }),
    }),
  }),
});

export const {
  useGetBagsQuery,
useGetBagDetailsQuery
} = bagsApi;
