import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { url } from 'components/other/terms';

export const appealsApi = createApi({
  reducerPath: 'appeals',

  baseQuery: fetchBaseQuery({
    baseUrl: url+'/appeals',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),

  tagTypes: ['Appeals'],

  endpoints: builder => ({
    getClientAppeals: builder.query({
      query: ({ page = 1 } = {}) => {

        const params = {
          page, // додаємо параметр page до запиту

        };
        return {
          url: ``,
          method: 'GET',
          params,
        };
      },
      transformResponse: (response, meta) => {
        const pagination = {
          currentPage: meta.response.headers.get('x-pagination-current-page'),
          pageCount: meta.response.headers.get('x-pagination-page-count'),
          perPage: meta.response.headers.get('x-pagination-per-page'),
          totalCount: meta.response.headers.get('x-pagination-total-count'),
        };
        return { data: response, pagination };
      },
      providesTags: ['Appeals'],
    }),
    deleteAppeals: builder.mutation({
      query: (id) => ({
        url: `/delete?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Appeals'],
    }),
  }),
});

export const {
  useGetClientAppealsQuery,
  useDeleteAppealsMutation
} = appealsApi;
